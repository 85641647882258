<template>
   <div class="container-fluid m-0 p-0">
		<div class="row m-0 p-0">
			<div class="col-lg-12 m-0 p-0">
            <headerPage />
            <router-view  />
            <footerPage />
         </div>
      </div>
   </div>
   
</template>
<script>
import headerPage from './components/Header/headerPage.vue';
import footerPage from './components/Footer/footerPage.vue';
export default {
   components:{
      headerPage,
      footerPage
   }
}
</script>
<template>
    
	<div class="site-mobile-menu site-navbar-target">
		<div class="site-mobile-menu-header">
			<div class="site-mobile-menu-close">
				<span class="icofont-close js-menu-toggle"></span>
			</div>
		</div>
		<div class="site-mobile-menu-body"></div>
	</div>

	<nav class="site-nav">
		<div class="container">
			<div class="site-navigation">
				<a href="javascript:void(0)" class="logo m-0">Tour <span class="text-primary"
						v-on:click="GoTo('/')">.</span></a>

				<ul class="js-clone-nav d-none d-lg-inline-block text-left site-menu float-right">
					<li class="active"><a href="javascript:void(0)" v-on:click="GoTo('/')">Home</a></li>
					<!-- <li class="has-children">
						<a href="javascript:void(0)">Dropdown</a>
						<ul class="dropdown">
							<li><a href="elements.html">Elements</a></li>
							<li><a href="javascript:void(0)">Menu One</a></li>
							<li class="has-children">
								<a href="javascript:void(0)">Menu Two</a>
								<ul class="dropdown">
									<li><a href="javascript:void(0)">Sub Menu One</a></li>
									<li><a href="javascript:void(0)">Sub Menu Two</a></li>
									<li><a href="javascript:void(0)">Sub Menu Three</a></li>
								</ul>
							</li>
							<li><a href="javascript:void(0)">Menu Three</a></li>
						</ul>
					</li> -->
					<li><a href="javascript:void(0)" v-on:click="GoTo('/services')">Services</a></li>
					<li><a href="javascript:void(0)" v-on:click="GoTo('/about')">About</a></li>
					<li><a href="javascript:void(0)" v-on:click="GoTo('/contactUs')">Contact Us</a></li>
				</ul>

				<a href="javascript:void(0)"
					class="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-none light"
					data-toggle="collapse" data-target="#main-navbar">
					<span></span>
				</a>

			</div>
		</div>
	</nav>


	<div class="site-mobile-menu site-navbar-target">
		<div class="site-mobile-menu-header">
			<div class="site-mobile-menu-close">
				<span class="icofont-close js-menu-toggle"></span>
			</div>
		</div>
		<div class="site-mobile-menu-body"></div>
	</div>

	<nav class="site-nav">
		<div class="container">
			<div class="site-navigation">
				<a href="javascript:void(0)" v-on:click="GoTo('/')" class="logo m-0">Tour <span
						class="text-primary">.</span></a>

				<ul class="js-clone-nav d-none d-lg-inline-block text-left site-menu float-right">
					<li class="active"><a href="javascript:void(0)" v-on:click="GoTo('/')">Home</a></li>
					<!-- <li class="has-children">
						<a href="#">Dropdown</a>
						<ul class="dropdown">
							<li><a href="elements.html">Elements</a></li>
							<li><a href="#">Menu One</a></li>
							<li class="has-children">
								<a href="#">Menu Two</a>
								<ul class="dropdown">
									<li><a href="#">Sub Menu One</a></li>
									<li><a href="#">Sub Menu Two</a></li>
									<li><a href="#">Sub Menu Three</a></li>
								</ul>
							</li>
							<li><a href="#">Menu Three</a></li>
						</ul>
					</li> -->
					<li><a href="javascript:void(0)" v-on:click="GoTo('/services')">Services</a></li>
					<li><a href="javascript:void(0)" v-on:click="GoTo('/about')">About</a></li>
					<li><a href="javascript:void(0)" v-on:click="GoTo('/contactUs')">Contact Us</a></li>
				</ul>

				<a href="#"
					class="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-none light"
					data-toggle="collapse" data-target="#main-navbar">
					<span></span>
				</a>

			</div>
		</div>
	</nav>
</template>
<script>
export default {
	name: "headerPage",

	data() {
		return {

		}
	},
	methods: {
		GoTo: function (link) {
			debugger; //eslint-disable-line
			this.$router.push(`${link}`);
		},

	},
}
</script>
import { createRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue'

const routes = [
    {
        path: '/',
        name: 'homePage',
        component:  defineAsyncComponent(() => import('../components/HomePage/homePage.vue'))
    },
    {
        path: '/about',
        name: 'aboutUs',
        component:  defineAsyncComponent(() => import('../components/aboutUs/aboutUs.vue'))
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component:  defineAsyncComponent(() => import('../components/ContactUS/contactUs.vue'))
    },
    {
        path: '/services',
        name: 'servicesPage',
        component:  defineAsyncComponent(() => import('../components/Services/servicesPage.vue'))
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

router.beforeEach((to, from, next) => {
    localStorage.setItem('CurrentPath', to.name);
    next();
    // if (to.name == "homePage") {
       
    // }
});
